.tabs-container-admin {
  display: flex;
  gap: 45px;
  list-style: none;
}

.tabs-container-admin .tab-item {
  padding: 15px 0;
  position: relative;
}

.custom-small-menu-item {
  color: #001B4F;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16.8px;
  padding: 15px 0;
}

.tab-active-item-admin:after {
  content: "";
  position: absolute;
  width: 60px;
  height: 4px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: var(--red-mmd-steel, #E31D1A);
}

.admin-cover-and-name-container {
  padding: 4rem 0;
  display: grid;
  place-items: center;
  & img {
    width: 84px;
    height: 84px;
  }
  & span {
    color: var(--on-surface-dark-high-emphasis, rgba(24, 24, 25, 0.90));
    font-family: DM Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28.8px;
    letter-spacing: -0.5px;
    padding-top: 13px;
  }
}

.custom-form-container {
  padding: 3rem 1.5rem;
}

.custom-form-container .custom-title {
  color: rgba(0, 0, 0, 0.22);
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28.8px;
  letter-spacing: -0.5px;
  padding-bottom: 2rem;
}

.custom-form-container .custom-label {
  color: #5A5A5A;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.c_grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px 90px;
}

.custom-input-style {
  background: transparent;
}

form .label-and-input-content {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 10px;
  color: #5A5A5A;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;

  & label {
    font-weight: 700;
    line-height: normal;
  }

  & input {
    outline: none;
    border-color: transparent;
    background-color: transparent;
    border-bottom: 1px solid #E9E9E9;;
    padding: 15px 10px;
    border-radius: 5px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 100%;
  }

  & span {
    position: absolute;
    top: 18%;
    right: 0;
    margin: auto;
    font-size: 18px;
    color: #5A5A5A;
    padding: 2px 6px;
    border-radius: 50%;
    transition: .3s ease;
    cursor: pointer;
  }
  & span:hover {
    background: #efefef;
  }
}

form .custom-sm-input {
  position: relative;
}

.custom-filtered-container {
  color: rgba(0, 0, 0, 0.60);
  text-align: center;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding-left: 10px;

  & input {
    border-radius: 10px;
    background: #F7F7F7;
    padding: 7px 10px;
    border-color: transparent;
    color: var(--grayscale-black, #252733);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    outline: none;
  }
}

.checkbox-add-permissions {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

input[type="checkbox"].input-checkbox-setting-item {
  display: none;
}
input[type="checkbox"].input-checkbox-setting-item + label::before {
  font-family: "Font Awesome 5 Free";
  content: '\f0c8';
  font-size: 1.2em;  
  color: #E31D1A;
  margin-right: 0.3rem  ;
}
input[type="checkbox"].input-checkbox-setting-item:checked + label::before {
  content:'\f14a';
  color: #E31D1A;
}
.input-checkbox-setting-item:checked + .content-input-checkbox-setting {
  border: 1px solid #E31D1A;
}
.content-input-checkbox-setting {
  margin-bottom: 0;
  border: 1px solid #E1E1E1;
  padding: 10px 20px;
  border-radius: 10px;
  flex-grow: 1;
  flex-basis: 0;
}



.btn-change-password {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 30px;
  gap: 10px;
  background: #E31D1A;
  border-radius: 8px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  color: #FFFFFF;
}

.btn-change-password:hover {
  background: #fff;
  border: 1px solid #E31D1A;
  color: #E31D1A;
}

/* ========================================================= */
.form-checkbox-input {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.checkbox-input-table label {
  display: flex;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  margin-bottom: 0.375em;
}

.checkbox-input-table label input {
  position: absolute;
  left: -9999px;
}

.checkbox-input-table label {
  margin-top: 6px;
  margin-bottom: 6px;
}

.checkbox-input-table label input:checked + span {
  box-sizing: border-box;
}

.checkbox-input-table label input:checked + span:before {
  box-shadow: inset 0 0 0 0.4375em #E31D1A !important;
}

.checkbox-input-table label span {
  display: flex;
  align-items: center;
  transition: 0.25s ease;
  width: 100%;
}

.checkbox-input-table label span:before {
  display: flex;
  flex-shrink: 0;
  content: "";
  background-color: #fff;
  width: 1.5em;
  height: 1.5em;
  margin-right: 0.375em;
  transition: 0.25s ease;
  box-shadow: inset 0 0 0 0.125em #666666;
}

.checkbox-input label {
  display: flex;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  margin-bottom: 0.375em;

  color: #868686;
  font-family: "s";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.checkbox-input label input {
  position: absolute;
  left: -9999px;
}

.checkbox-input label {
  margin-top: 6px;
  margin-bottom: 6px;
}

.checkbox-input label input:checked + span {
  border: 2px solid var(--Bleu-ciel, #E31D1A) !important;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 15px;
}

.checkbox-input label input:checked + span:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f00c";
  background: #E31D1A !important;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  box-shadow: inset 0 0 0 0.125em #E31D1A !important;
}

.checkbox-input label span {
  display: flex;
  align-items: center;
  padding: 0.375em 0.75em 0.375em 0.375em;
  border-radius: 10px;
  transition: 0.25s ease;
  padding: 15px;
  border: 2px solid #ededed;
}

.checkbox-input label span:hover {
  border: 2px solid #E31D1A !important;
}

.checkbox-input label span:before {
  display: flex;
  flex-shrink: 0;
  content: "";
  background-color: #fff;
  width: 24px;
  height: 24px;
  margin-right: 0.375em;
  transition: 0.25s ease;
  box-shadow: inset 0 0 0 0.125em #ededed;
}

.container-checkbox-input {
  width: 100%;
  align-items: center;
}

.custom-permission-container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
/* ========================================================= */

/* =============== MESSAGES CONTACT ================= */
.message-btn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  line-height: 11px;
  color: #ffffff;
  letter-spacing: 0.5px;
  border-radius: 100px;
  text-transform: uppercase;
  border: none;
  text-align: center;
  background: #357DBF;
}
.bg-status {
  background: #da7943 !important;
}

.bg-status-success {
  background: #35B7BF !important;
}

.admin-message-contact-card-frequence {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 25px;
  letter-spacing: 0.4px;
  color: #9fa2b4;
}
.fz-14 {
  font-size: 14px !important;
}

.admin-message-contact-card-description,
.admin-message-contact-card-description p,
.admin-message-contact-card-description p span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 28px;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.7);
  text-align: start;
}

.admin-message-contact-card {
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 4px 20px 0px #F1F1F1;
  padding: 12px;
}

.message-text p {
  color: #000000;
}
.accordion-button {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  width: 20px !important;
}
.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23A81912'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23A81912'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
/* .admin-content-message,
.admin-content-message p {
  font-family: "Poppins";
  font-style: normal;
  font-size: 13px;
  line-height: 131.7%;
  color: rgba(0, 0, 0, 0.65);
} */
.message-item-title {
  font-weight: 600 !important;
}
.admin-content-message p {
  font-weight: 400;
}


.admin-content-message,
.admin-content-message p {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 131.7%;
    color: rgba(0, 0, 0, 0.65);
}

.custom-container-btn-modal {
    display: flex;
    gap: 15px;
    justify-content: flex-end;
}

.btn-reply-contact-container {
    display: flex;
    justify-content: flex-end;
}

.btn-reply-contact {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
}

.ql-description .ql-editor {
    min-height: 150px;
}

.text-response-card p {
    color: #000000;
    font-size: 15px;
    font-weight: 400;
}

.text-response-card {
    color: #000;
}
.text-date-message {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #586a84;
}
.content-label-img-profil-mmessage {
  display: flex;
  gap: 10px;
  align-items: center;
}
.img-profil-mmessage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.text-name-profil-message {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #3b4758;
  white-space: nowrap;
}

.badge {
  color: #fff;
  border-radius: 10px;
  padding: 8px;
  font-weight: 500 !important;
}

.badge.mise_en_relation {
  color: #9203D7 !important;
  border: 1px solid #9203D7;
  background: #fff;
}

.badge.plainte {
  color: orange !important;
  border: 1px solid orange;
  background: #fff;
}

.badge.new {
  background: #9203D7;
}

.badge.in_progress {
  background: orange;
}

.badge.traited {
  background: #1AA981;
}
/* =============== FIN MESSAGES CONTACT ================= */
