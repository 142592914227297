.btn-action-filter-icon,
.btn-action-filter-icon:hover {
  color: #084cb3;
  border: none;
  background: transparent;
  width: 30px;
  height: 30px;
  padding: 0;
  border-radius: 50%;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-action-icon-delete,
.btn-action-icon-delete:hover {
  color: #fa1414;
  border: none;
  background: #fa141421;
  width: 30px;
  height: 30px;
  padding: 0;
  border-radius: 50%;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-action-icon-edit,
.btn-action-icon-edit:hover {
  color: #E97D24;
  border: none;
  background: #e97d2425;
  width: 30px;
  height: 30px;
  padding: 0;
  border-radius: 50%;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-action-modal-icon,
.btn-action-modal-icon:hover {
  color: #158A91;
  border: none;
  background: #C2E9EC;
  width: 30px;
  height: 30px;
  padding: 0;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.btn-action-modal-icon::after {
  content: attr(data-tooltip-content);
  padding: 8px;
  border-radius: 4px;
  position: absolute;
  top: 77%;
  right: 50%;
  left: 50%;
  width: max-content;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #158A91;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, 18px) scale(0.8);
  transition: visibility, opacity, transform 200ms;
}
.btn-action-modal-mail,
.btn-action-modal-mail:hover {
  color: #219653;
  border: none;
  background: transparent;
  width: 30px;
  height: 30px;
  padding: 0;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.btn-action-modal-mail::after {
  content: attr(data-tooltip-content);
  padding: 8px;
  border-radius: 4px;
  position: absolute;
  top: 77%;
  right: 50%;
  left: 50%;
  width: max-content;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #219653;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, 18px) scale(0.8);
  transition: visibility, opacity, transform 200ms;
}
.btn-action-filter-icon::after {
  content: attr(data-tooltip-content);
  padding: 8px;
  border-radius: 4px;
  position: absolute;
  top: 77%;
  right: 50%;
  left: 50%;
  width: max-content;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #084cb3;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, 18px) scale(0.8);
  transition: visibility, opacity, transform 200ms;
}
.btn-action-icon-delete::after {
  content: attr(data-tooltip-content);
  padding: 8px;
  border-radius: 4px;
  position: absolute;
  top: 77%;
  right: 50%;
  left: 50%;
  width: max-content;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #fa1414;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, 18px) scale(0.8);
  transition: visibility, opacity, transform 200ms;
}

.btn-action-icon-edit::after {
  content: attr(data-tooltip-content);
  padding: 8px;
  border-radius: 4px;
  position: absolute;
  top: 77%;
  right: 50%;
  left: 50%;
  width: max-content;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #E97D24;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, 18px) scale(0.8);
  transition: visibility, opacity, transform 200ms;
}
.with-tooltip {
  position: relative;
}
.with-tooltip:hover::after {
  visibility: visible;
  opacity: 1;
  transform: translate(-50%, 0);
}
.statut-commande {
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  padding: 15px;
}
.accepte {
  background: #effef8;
  color: #08bb90;
}
.annule {
  color: #eb5757;
  background: rgba(250, 20, 20, 0.1);
}

.admin-table thead {
  border-bottom: 1px solid rgb(247, 247, 251);
  height: 50px;
}
.admin-table table thead tr th {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
}
.admin-table table tbody tr td,
.admin-table table tbody tr td p {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #3B4758;
}
.admin-table .table thead tr th,
.admin-table .table tbody tr td {
  text-align: left !important;
  vertical-align: middle;
}
.admin-table .table tbody tr {
  background: #F1F1F1;
  border-radius: 0px;
  border-bottom: 5px solid #fff;
  height: 70px;
}
.admin-table .table {
  border: transparent;
  --bs-table-bg: transparent !important;
}
.img-profil-praticient-table {
  border-radius: 6px;
  width: 40px;
  height: 40px;
}
.name-profil-table {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700 !important;
  font-size: 14px;
  line-height: 18px;
  color: #3B4758 !important;
}
