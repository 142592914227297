.btn-theme-body-page {
    box-shadow: 0px 2px 10px 0px #00000012;
    background: #fff;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0.4000000059604645px;
    color: #252733;
    padding: 15px;
    text-decoration: none;
}
.btn-theme-body-page svg {
    color: #E31D1A;
}
.btn-theme-body-page:hover {
    background: #E31D1A;
    color: #fff;
}
.btn-theme-body-page:hover svg {
    color: #fff;
}
.page-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}