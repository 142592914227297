.modal-add-btn-action-container {
    display: flex;
    gap: 15px;
    justify-content: flex-end;
    align-items: center;
}
.admin-table .table thead tr > th:nth-child(4) {
    text-align: center;
}

.control-fer-btn-close-modal-container {
    position: absolute;
    right: 15px;
    top: 15px;
}

.custom-btn-close {
    border: 1px solid #E4E6EB;
    border-radius: 50%;
}

.container-form label {
    color: #1B1B1B;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}

.container-form input, .container-form textarea {
    border-radius: 5px;
    background: #F2F3F3;
}

.container-form textarea {
    width: 100%;
    border-color: transparent;
    padding: 1rem;
    outline: none;
}

.annonce-quill .ql-toolbar {
    background: #F2F3F3;
}

.annonce-quill .ql-editor {
    background: #F2F3F3;
    min-height: 150px;
}

.annonce-title-swal {
    text-align: start !important;
}

.annonce-html-container {
    text-align: justify !important;
    line-height: 25px !important;
}
.annonce-html-container p,
.annonce-html-container ol,
.annonce-html-container ul,
.annonce-html-container pre,
.annonce-html-container blockquote,
.annonce-html-container h1,
.annonce-html-container h2,
.annonce-html-container h3,
.annonce-html-container h4,
.annonce-html-container h5,
.annonce-html-container h6 {
    margin: 0;
    padding: 0;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    color: #252733;
}