.page-content-base-container {
  transition: 0.75s;
  overflow: auto;
  width: 100% !important;
  display: flex;
  justify-content: flex-end;
}

.user-dash-page-content-container {
  background: #ffffff;
  padding: 20px 34px;
}
.item-stat-dashboad-view {
  padding: 10px 20px;
  width: 100%;
  position: relative;
  align-items: center;
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
  border-radius: 15px;
  background: #fff;
}

.titre-item-stat-dashboad-view {
  font-family: "Poppins";
font-size: 13px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0em;
text-align: left;

  color: #747474;
}
.chiffre-item-stat-dashboad-view {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  color: #586A84;
}
.text-describe-dash-view {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  color: #586A84;
}
.content-text-item-stat-dashboad-view {
  width: 100%;
}

.admin-page-container-layout {
  margin-top: 90px;
}
.content-stat-dashboard-view {
  display: flex;
  justify-content: space-between;
}
.content-chart-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-control-select-date-graph {
  background: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.07);
  border-radius: 100px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.4px;
  color: #252733;
  width: 300px;
  padding: 15px;
  border: 0;
  background: url(../../../assets/icons/Shape.png) 94% 47% / 13px no-repeat #fff !important;
}
.form-control-select-dashboard {
  background: #fff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.4px;
  color: #000;
  padding: 15px;
  border: 0;
  background: url(../../../assets/icons/chevron-down.png) 94% 47% / 13px no-repeat #fff !important;
}
.chart-item-admin-title {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  color: #000000;
}

.title-content-dash-admin {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #000000;
}
.container-title-content-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title-content-dash-admin {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #000000;
}
.content-graph-admin {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
}
.text-statut-table {
  font-family: "Poppins";
  font-size: 10px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.20000000298023224px;
  color: #757F8C;
}
.admin-page-title {
  font-family: "Poppins";
  font-size: 26px;
  font-weight: 600;
  line-height: 39px;
  letter-spacing: 0em;
  color: #011F3B;
}

.custom-top-content {
  padding-bottom: 2rem;
  display: flex;
  justify-content: space-between;
}

.custom-top-content .custom-add-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  padding: 12px 20px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.07);
  color: var(--grayscale-black, #252733);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.4px;

  & span {
    color: var(--red-mmd-steel, #E31D1A);
    font-size: 18px;
  }
}
.react-datepicker__input-container {
  background: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.07);
  border-radius: 100px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.4px;
  color: #252733;
  width: 300px;
  padding: 15px;
  border: 0;
  background: url(../../../assets/icons/Shape.png) 94% 47% / 13px no-repeat #fff !important;
}
.react-datepicker__input-container input[type="text"] {
  border: none;
}
.react-datepicker-wrapper {
  width: 105% !important;
}