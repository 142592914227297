body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.no-link,
.no-link:hover,
.no-link.active {
  text-decoration: none;
  color: inherit;
  border: inherit !important;
}

._2Jtxm {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}
._2JvrO svg {
  font-size: 10px !important;
  width: 15px !important;
  height: 15px !important;
}

.edk-alert {
  border-left: 6px solid #09132d;
  border-radius: 0.4rem;
  color: #09132d;
  padding: 2rem 1%;
  display: flex;
  align-items: center;
  gap: 5px;
}

.edk-alert-info {
  background-color: rgba(50, 175, 239, 0.1);
}

.edk-alert h4 {
  margin: 0;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 18px;
}
.pagination {
  position: relative;
  align-items: center;
  margin-top: 40px;
  justify-content: center;
}
.page-item.active .page-link {
  background-color: #e42d29 !important;
  border-color: #e42d29 !important;
  color: #fff !important;
  font-weight: 900;
}

li.page-item a {
  color: #000 !important;
}

.page-item:first-child .page-link span {
  color: #000 !important;
}

.page-item.disabled .page-link span {
  color: #000 !important;
}

.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
  margin: 0;
  padding: 0;
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  color: #252733;
}

.swal2-backdrop-show {
  z-index: 999999 !important;
}
.error-page-section-row {
  padding: 50px 0 150px 0;
  align-items: center;
}
.error-page-section-title {
  color: #E31D1A !important;
}
.error-page-textual p {
  color: #E31D1A !important;
}
.return-to-homepage-btn {
  background: #E31D1A !important;
  color: #ffffff !important;
  padding: 10px 30px;
  border-radius: 15px;
  border-color: #E31D1A !important;
  transition: all 0.5s cubic-bezier(0.42, 0, 0.58, 1);
}
.error-page-code-textual-container {
  padding: 100px;
}
.error-page-code {
  font-size: 200px;
  color: #E31D1A !important;
}
#error-page-section {
  background: #f1f1f9 ;
}
.return-to-homepage-btn:hover {
  background: transparent !important;
  color: #E31D1A !important;
  border-radius: 50px;
}

.product-img-card {
  background: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  padding: 2rem 0;
  position: relative;
}