.text-libelle-info-perso {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
    letter-spacing: 0em;
    color: #000000;
}
.text-value-info-perso {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    color: #000000;
}

.logo-entity {
    width: 150px !important;
    height: 150px !important;
    object-fit: fill;
    border-radius: 50%;
}
.img-avatar-itemt-table {
    width: 29.931px;
    height: 29.931px;
    border-radius: 50%;
}