.empty-header-auth {
  background-color: #fff;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.04), 0px 4px 56px #0000000a;
  z-index: 1;
  position: relative;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.auth-component {
  background: #f5f5f5;
}
.auth-left-side-container {
  background-image: url(../../assets/appImages/auth.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  width: 100%;
}
.content-text-message-login {
  display: flex;
  justify-content: flex-end;
}
.auth-right-side-container {
  padding: 100px 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.auth-form-title {
  font-family: "Poppins";
  font-weight: 700;
  line-height: 54px;
  letter-spacing: 0em;
  text-align: center;
  color: #1C1C1C;
  font-size: clamp(30px, 1vw, 36px);
  white-space: nowrap;
}
.auth-form-control {
  background: #F2F3F3;
  border-radius: 5px;
  padding: 20px;
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: #444150 !important;
  border: 1px;
}
.auth-form-control:focus {
  border: 1px solid #E31D1A;
}
.auth-form-group {
  position: relative;
}

.forget-password-link {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #E31D1A;
  text-decoration: none;
}
.forget-password-link:hover {
  text-decoration: none;
  color: #303E48;
  font-weight: bold;
}
.auth-form-message {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: center;
}
.btn-text-message-login {
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  text-decoration: none;
  color: #1c208f;
}
.btn-text-message-login:hover {
  text-decoration: underline;
  color: #1c208f !important;
}
.auth-submit-btn {
  background: #E31D1A;
  border-radius: 10px;
  width: 100%;
  padding: 15px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  text-transform: uppercase;
  color: #ffffff;
}
.auth-submit-btn:hover {
  background: #fff;
  color: #E31D1A;
  border: 1px solid #E31D1A;
  text-decoration: none;
}
.container-page-login {
  height: 100vh;
}
.auth-row.row .auth-col {
  padding: 0 !important;
}

.form-control:focus {
  box-shadow: none !important;
}
.content-form-login-page {
  padding: 30px 15px;
}

.fixed-after-header {
  margin-top: 4%;
}

.form-label-auth-login {
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  color: #555555;
}
.auth-right-side-col {
  background: #fff;
}
.show-hide-password {
  position: absolute;
  right: 4%;
  color: #303E48;
  top: 23%;
  font-size: 20px;
}

.radio-group {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

input[type="radio"] {
  display: none;
}

.label-input-register {
  position: relative;
  padding-left: 30px;
  color: #444150;
  cursor: pointer;
  transition: color 0.3s;
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
}

.label-input-register:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid #808080;
  transition: background-color 0.3s;
}

input[type="radio"]:checked + .label-input-register:before {
  background-color: #1c208f;
}

input[type="radio"]:focus + .label-input-register {
  color: #1c208f;
}

input[type="radio"]:checked + .label-input-register {
  color: #1c208f;
}

input[type="radio"]:hover + .label-input-register:before {
  background-color: #1c208f;
}

input[type="radio"]:focus + .label-input-register:before {
  background-color: #1c208f;
}
.input-with-eye-container {
  position: relative;
}